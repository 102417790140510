// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"qb-production-release@2025-04-01T22:30:29.258Z"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import { init } from '@sentry/nextjs';
import { getBaseSentryClientConfig } from '@qb/base-config/base-sentry.client.config';

init({
  ...getBaseSentryClientConfig(false),
  dsn: 'https://a8343e3474a641dfae0a9c649be5a56a@o4504018562842624.ingest.us.sentry.io/4504018679562241',
  tracesSampleRate: 0.5,
});
